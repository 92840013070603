<template>
  <div>
    <template v-if="isSetupLoading">
      <v-row class="justify-center ma-0 pa-0">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </template>
    <template v-else>
      <v-row class="pa-0 ma-0 pr-6">
        <v-col cols class="pa-0 ma-0">
          <MenuTabs :menuOptions="menuOptions" @setMenuOption="setMenuOption" @selectedProperty="setSelectedProperty"
            :showAside="showSidebar"></MenuTabs>
        </v-col>
      </v-row>
      <!-- Main Content Area -->
      <div class="primary-section-content pr-6">
        <div>
          <component :is="componentId" :automation-info="selectedAutomation" :property.sync="property" />
        </div>
        <sidebar-menu :activeIndex="selectedIndex" :menuItems="allAutomationTypes" v-model="showAside"
          @openView="openTheView"></sidebar-menu>
      </div>
    </template>
    <v-navigation-drawer :value="showDrawer" width="1200" fixed stateless class="z-250">
      <ManageStage v-if="showDrawer" @close="closeDrawer" @refresh="refresh" :selected_stage="selected_stage"
        :automation_group_id="automation_group" :group_id="trigger_group" :automation="automation" :property_id="property_id" :sectionIdentifier="'manage-stage'" :selectedAutomationType="selectedAutomationType" />
    </v-navigation-drawer>
    <v-overlay :value="showDrawer"></v-overlay>
  </div>
</template>

<script type="text/babel">
import MenuTabs from "../Common/MenuTabs.vue";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../../../src/EventBus.js';
import SidebarMenu from "../Common/SidebarMenu.vue";
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import ManageStage from "../Automation/ManageStage.vue";
import AUTOMATION from '@/constants/automation.js';

export default {
  name: "Automations",
  mixins: [notificationMixin],
  data() {
    return {
      menuOptions: [
        {
          key: "corporate",
          label: "Corporate Settings",
        },
        {
          key: "property",
          label: "Property Settings",
        },
      ],
      activeTab: "corporate",
      property: {},
      isSetupLoading: false,
      showAside: true,
      showDrawer: false,
      showSidebar: true,
      selectedIndex: 0,
      selectedAutomation: {},
      trigger_group: null,
      automation_group: null,
      selected_stage: {},
      property_id: null,
      automation: {},
      selectedAutomationType:{}
    };
  },
  components: {
    MenuTabs,
    SidebarMenu,
    ManageStage,
    corporate: () => import("./CorporateTemplate.vue"),
    property: () => import("./PropertyTemplate.vue"),
  },
  async created() {
    this.isSetupLoading = true;
    await this.loadAutomations(this.allAutomations);  
    EventBus.$on('showHideSidebar', this.showHideSidebar);
    EventBus.$on('openStageDrawer', this.handleOpenStageDrawer);
  },
  computed: {
    ...mapGetters({
      allAutomationTypes: 'automationStore/getAllAutomationTypes',
      error: 'automationStore/getError'
    }),
    componentId() {
      return this.activeTab;
    },
    allAutomations(){
      return this.allAutomationTypes || [];
    }
  },

  methods: {
    ...mapActions({
      setNotification: "automationStore/setNotification",
      fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
      fetchTriggerActionDependencyTypes: 'automationStore/fetchTriggerActionDependencyTypes',
      fetchAllMissingActions: 'automationStore/fetchAllMissingActions',
      fetchTaskTypeConfigurations: 'taskCenterStore/fetchTaskTypeConfigurations', 
    }),
    async setMenuOption(option) {
      this.activeTab = option;
      this.property = {};
    },
    async setSelectedProperty(property) {
      this.property = property;
    },
    async showHideSidebar(val) {
      this.showAside = (val === true) ? true : false;
    },
    handleOpenStageDrawer(data) {
      this.trigger_group = data.trigger_group_id;
      this.automation_group = data.automation_group_id;
      this.selected_stage = data.stage;
      this.property_id = data.property_id;
      this.automation = data.automation;
      this.showDrawer = true;
      this.selectedAutomationType = data.selectedAutomationType
    },
    async openTheView(page) {
      if(page && page?.name == AUTOMATION.EVENT_TASK) {
        await this.fetchTaskTypeConfigurations();
      }
      this.selectedAutomation = page;
      if(this.selectedAutomation && typeof this.selectedAutomation === 'object' && Object.keys(this.selectedAutomation || {}).length > 0 && this.selectedAutomation.automation_category_id){
        await this.fetchTriggerActionDependencyTypes({automation_category_id: this.selectedAutomation.automation_category_id});
      }
    },
    closeDrawer() {
      this.selected_stage = {};
      this.showDrawer = false;
    },
    async refresh() {
      await this.fetchAllWorkflows({
        automation_type_id: this.selectedAutomation.id,
        property_id: this.property_id
      });
      if(this.selectedAutomation && this.selectedAutomation?.id){
        this.fetchAllMissingActions({automation_type_id: this.selectedAutomation?.id,property_id: this.property_id || ''});
      }
      EventBus.$emit('refresh', this.automation_group);
    },
    async loadAutomations(automations){
      if(automations && automations.length > 0){
        this.selectedIndex = await automations.findIndex(item => item.default_selected === 1) || 0;
        this.selectedAutomation = automations[this.selectedIndex];
        if(this.selectedAutomation && typeof this.selectedAutomation === 'object' && Object.keys(this.selectedAutomation || {}).length > 0 && this.selectedAutomation.automation_category_id){
          await this.fetchTriggerActionDependencyTypes({automation_category_id: this.selectedAutomation.automation_category_id});
          this.isSetupLoading = false;
        }
      }
    }
  },
  destroyed() {
    EventBus.$off('showHideSidebar', this.showHideSidebar);
    EventBus.$off('openStageDrawer');
  },
  watch: {
    async allAutomations(automations){
      this.isSetupLoading = true;
      await this.loadAutomations(automations);  
    }
  }
};
</script>

<style lang="scss" scoped>
.primary-section-content {
  display: flex;
  height: calc(100% - 70px);
  flex-direction: row;

  &>div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>