<template>
  <v-navigation-drawer v-model="showAside" :right="!$vuetify.breakpoint.xs" :hide-overlay="$vuetify.breakpoint.smAndUp"
    :clipped="!$vuetify.breakpoint.xs" :class="{ 'attached-drawer': !$vuetify.breakpoint.xs }" disable-resize-watcher
    mobile-breakpoint="600" class="ma-0 pa-0 py-1 hb-tertiary-navigation sidebar-menu-wrapper"
    :width="showAside ? '250px' : '0px'" :bottom="$vuetify.breakpoint.xs" :app="$vuetify.breakpoint.xs" flat>
    <v-list dense style="width:100%;">
      <v-list-item-group v-model="selectedIndex" mandatory>
        <v-list-item v-for="(item, index) in menuItems" v-bind:key="index" @click="$emit('openView', item)" exact
          class="pl-5">
          {{ item.name }}
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-navigation-drawer>
</template>


<script type="text/babel">
export default {
  name: "SidebarMenu",
  data() {
    return {
      selectedIndex: 0
    };
  },
  props: {
    menuItems: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
  },
  created() {
    this.selectedIndex = this.activeIndex;
  },
  computed: {
    showAside: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.sidebar-menu-wrapper {
  height: auto !important;
}

.sidebar-menu-wrapper .v-navigation-drawer__content .v-list-item {
  line-height: 1.3;
  padding-top: 8px;
  padding-bottom: 8px;
}

.sidebar-menu-wrapper .v-navigation-drawer__content .v-list-item:not(.v-list-item--active) {
  background-color: #f9fafb !important;
  border-left: 4px solid #f9fafb;
}

.sidebar-menu-wrapper .v-navigation-drawer__content .v-list-item--active {
  background-color: #e2f2f3 !important;
  border-left: 4px solid #00848E;
  font-weight: 500 !important;
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  display: none !important;
}
</style>

<style>
.sidebar-menu-wrapper .v-navigation-drawer__content .v-list-item {
  min-height: 24px !important;
}
</style>